import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme }) => theme.color.backgroundSectionOne};
  height: 700px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${breakpoints.l}px) {
    height: 400px;
  }
`

export const MotionContainer = styled(motion.div)`
  height: 800px;

  @media (max-width: ${breakpoints.l}px) {
    height: 500px;
  }
`

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const Text = styled.h1`
  text-align: center;
  font-weight: 400;
  font-style: italic;
  font-size: 56px;
  max-width: 800px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
`

export const Name = styled.p`
  margin-top: 32px;
  text-align: center;
`
