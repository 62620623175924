import React from "react"
import { graphql, navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Splash from "../components/sections/Splash"
import TextImage from "../components/sections/TextImage"
import CoverImage from "../components/sections/CoverImage"
import ConflictCards from "../components/sections/ConflictCards"
import Quote from "../components/sections/Quote"

const IndexPage = ({ data }) => {
  const intl = useIntl()

  const { markdownRemark, allFile } = data
  const {
    title,
    introTitle,
    introText,
    backgroundTitle,
    backgroundText,
    quoteText,
    quoteName,
  } = markdownRemark.frontmatter
  const splashImage = allFile.nodes.find(image => image.name === "unhome")
  const beretImage = allFile.nodes.find(image => image.name === "blueberet")
  const middleImage = allFile.nodes.find(image => image.name === "unmiddle")
  const quoteImage = allFile.nodes.find(image => image.name === "quote")

  return (
    <Layout>
      <SEO title={title} />
      <Splash title={title} image={splashImage} />
      <TextImage
        title={introTitle}
        description={introText}
        image={beretImage}
        buttonText={intl.formatMessage({ id: "index.teacher_btn_text" })}
        onClick={() => navigate("/teachers")}
        backgroundColor="#FFFFFF"
        leftAlign
      />
      <CoverImage image={middleImage} />
      <TextImage
        title={backgroundTitle}
        description={backgroundText}
        buttonText={intl.formatMessage({ id: "index.more_btn_text" })}
        onClick={() => navigate("/background-history")}
        leftAlign
      />
      <ConflictCards
        title={intl.formatMessage({ id: "index.conflicts_title" })}
      />
      <Quote text={quoteText} name={quoteName} image={quoteImage} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery($locale: String) {
    markdownRemark(frontmatter: { lang: { eq: $locale }, slug: { eq: "/" } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        introTitle
        introText
        backgroundTitle
        backgroundText
        quoteText
        quoteName
      }
    }
    allFile(filter: { relativeDirectory: { eq: "startpage" } }) {
      nodes {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default IndexPage
