import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useRefScrollProgress } from "../../hooks/UseRefScrollProgress"
import { Section, MotionContainer, Overlay, Text, Name } from "./styles"

const Quote = ({ text, name, image }) => {
  const { ref, start, end } = useRefScrollProgress()
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [start, end + 0.2], [1.2, 1])
  const translateY = useTransform(
    scrollYProgress,
    [start, end + 0.2],
    ["0", "-100px"]
  )
  return (
    <Section ref={ref}>
      <MotionContainer style={{ scale, translateY }}>
        <Img
          fluid={image.childImageSharp.fluid}
          alt="quote"
          style={{ position: "inherit" }}
        />
      </MotionContainer>
      <Overlay>
        <motion.div style={{ scale }}>
          <Text>{text}</Text>
          <Name>{name}</Name>
        </motion.div>
      </Overlay>
    </Section>
  )
}

Quote.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.object,
}

export default Quote
